import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import { LoadWasm } from './wasm/LoadWasm';
import { ErrorBoundary } from "./ErrorBoundary"
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    {/* <LoadWasm> */}
    <BrowserRouter>
      <ErrorBoundary>
        <App ></App>
      </ErrorBoundary>
      </BrowserRouter>
    {/* </LoadWasm> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

console.log(process.env)