import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Link } from "react-router-dom";

import './accordion.css';


function PostMenuItems() {
    return (
        <nav aria-label="main mailbox folders">
            <List>
                <ListItem disablePadding>
                    <Link className="menu_item" to="/post/audit/list">待审核</Link><br />
                </ListItem>
                <ListItem disablePadding>
                    <Link className="menu_item" to="/post/all">所有作品</Link><br />
                </ListItem>
            </List>
        </nav >
       
    );
}

function UserMenuItems() {
    return (
        <nav aria-label="main mailbox folders">
            <List>
                <ListItem disablePadding>
                    <Link className="menu_item" to="/user/all">用户列表</Link><br />
                </ListItem>
            </List>
        </nav>
           
    );
}

function Home() {
    return (
        <nav aria-label="main mailbox folders">
            <List>
                <ListItem disablePadding>
                    <Link className="menu_item" to="/home">概览</Link><br />
                </ListItem>
            </List>
        </nav>
           
    );
}


export default function MenuAccordion() {
    return (
        <div className="menu_box">
             <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <Typography>首页</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Home />
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <Typography>帖子管理</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <PostMenuItems />
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header">
                    <Typography>用户管理</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <UserMenuItems />
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
