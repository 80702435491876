// import PageRouter from './routers';
// import PrimarySearchAppBar from './components/appbar/appbar';
import MenuAccordion from './components/accordion/accordion';
import { Routes, Route } from "react-router-dom";
import { lazy, Suspense } from 'react';
// import loadable from '@loadable/component';
import './App.css';

const AuditListComponent = lazy(() => import('./components/post-list/audit-list'));
const PostAll = lazy(() => import('./components/post-list/all-list'));
const UesrListComponent = lazy(() => import('./components/user-list/user-list'));
const LoginComponent = lazy(() => import('./components/login/login'));
const Page404 = lazy(() => import('./components/404/404'));
const Home = lazy(() => import('./components/home/home'));

function App() {
  return (
    <div className="app-container">
      {/* 左边菜单栏 */}
      <MenuAccordion ></MenuAccordion>

      {/* header 顶部 */}
      {/* <PrimarySearchAppBar /> */}

      <div className="content_box">
        <Suspense fallback={<p>loading</p>}>
          <Routes>
            <Route path="/" element={<LoginComponent />} />
            <Route path="/home" element={<Home />} />
            <Route path="/qrlogin" element={<LoginComponent />} />
            <Route path="/post/audit/list" element={<AuditListComponent />} />
            <Route path="/post/all" element={<PostAll />} />
            <Route path="/user/all" element={<UesrListComponent />} />
            <Route path="*" element={<Page404 />} />
          </Routes>
        </Suspense>
      </div>
    </div>
  );
}

export default App;
